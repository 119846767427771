import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import {graphql, Link} from "gatsby";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock, faCreditCard, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import Carrousel from "../components/Carrousel";
import Like from "../components/Like";
import SEO from "../components/seo";
import Helmet from "react-helmet";

export default ({data: {datoCmsShoppingpackage: p}}) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    mainEntityOfPage: {
      "@type": "WebPage"
    },
    name: p.name,
    image: [
      `${p.image.url}?ar=1:1&fit=crop`,
      `${p.image.url}?ar=4:3&fit=crop`,
      `${p.image.url}?ar=16:9&fit=crop`,
    ],
    description: p.description,
    offers: {
      "@type": "Offer",
      priceCurrency: "USD",
      price: p.pricedollar,
      seller: {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "LookStyler",
        url: "https://www.lookstyler.com",
        logo: {
          "@type": "ImageObject",
          url: "https://www.datocms-assets.com/10110/1552464165-lookstyler-secondary-mark-black.png"
        },
      }
    }
  }
  return (
    <Layout>
      <SEO title={`Shopping package ${p.name}`} seo={p.seoMetaTags}/>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      {p.image && <Img fluid={p.image.fluid} alt={p.name}/>}
      <div className="flex flex-col justify-center items-start md:mx-32 mx-2 my-6 md:my-16">
        <h1 className='font-title tracking-wide title text-black text-center text-4xl mb-4'>{p.name}</h1>
        <div className="font-sans font-light text-black text-lg mb-8"
             dangerouslySetInnerHTML={{__html: p.description}}/>
        <div className="flex flex-wrap md:flex-no-wrap justify-between items-start w-full">
          <div className="flex flex flex-col items-start mb-8 mr-2">
            <h1
              className='font-title tracking-wide title text-gray-700 text-center text-2xl mb-2 md:mb-4'>Package
              includes</h1>
            <div className="font-sans font-light text-gray-700 text-lg mb-8"
                 dangerouslySetInnerHTML={{__html: p.content}}/>
            <div className='mb-2'>
              <FontAwesomeIcon className='text-green-700' icon={faClock} size="1x"/>
              <span className={'ml-2 font-sans text-gray-800'}>{p.durationhours} hours</span>
            </div>
            <div className='mb-2'>
              <FontAwesomeIcon className='text-green-700' icon={faCreditCard} size="1x"/>
              <span className={'ml-2 font-sans text-gray-800'}>{p.pricedollar}$</span>
            </div>
            <div className='mb-2'>
              <FontAwesomeIcon className='text-green-700' icon={faMapMarkerAlt} size="1x"/>
              <span className={'ml-2 font-sans text-gray-800'}>{p.city && p.city.name}</span>
            </div>
            <Like model={'shoppingPackage'} id={p.originalId}/>
            <Link to={'/booking/?shoppingPackageId=' + p.originalId}
                  className='mt-4 text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'>Book
              now</Link>
          </div>
          <div className="w-full md:w-1/3 flex flex-col items-start">
            <h1
              className='font-title tracking-wide title text-gray-700 text-center text-2xl mb-2 md:mb-4'>Ambiance</h1>
            <Carrousel children={p.images.map(i => <Img fluid={i.fluid} alt={p.name}/>)}></Carrousel>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
    query ShoppingPackage($slug:String)  {
        datoCmsShoppingpackage(slug:{eq:$slug}) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            id
            originalId
            name
            description
            content
            durationhours
            pricedollar
            city {
                name
            }
            image {
                url
                fluid(maxWidth: 1500, imgixParams: {fit: "crop", w:"1000", h:"400"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
            images {
                fluid(maxWidth: 500, imgixParams: {fit: "crop", w:"500", h:"600"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }
`
